"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollegeSeasonGame = exports.CollegeSeason = exports.RcrtStaffChange = exports.CollegeRef = exports.Conference = exports.College = exports.COLLEGE_GENDERS = exports.COLLEGE_SPORTS = exports.ERcrtScrapingErrors_MAP = exports.ERcrtScrapingErrors = exports.allLinkTypes = exports.conferenceLinkTypes = exports.collegeLinkTypes = exports.eventLinkTypes = exports.LOCK_LEVELS = exports.COLLEGE_SUBDIVISIONS = exports.COLLEGE_DIVISIONS = exports.COLLEGE_SITE_PROVIDERS_MAP = exports.COLLEGE_SITE_PROVIDERS = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
const date_fns_1 = require("date-fns");
var COLLEGE_SITE_PROVIDERS;
(function (COLLEGE_SITE_PROVIDERS) {
    COLLEGE_SITE_PROVIDERS[COLLEGE_SITE_PROVIDERS["NONE"] = 0] = "NONE";
    COLLEGE_SITE_PROVIDERS[COLLEGE_SITE_PROVIDERS["SIDEARM"] = 1] = "SIDEARM";
    COLLEGE_SITE_PROVIDERS[COLLEGE_SITE_PROVIDERS["PRESTO"] = 2] = "PRESTO";
    COLLEGE_SITE_PROVIDERS[COLLEGE_SITE_PROVIDERS["WMT"] = 3] = "WMT";
    COLLEGE_SITE_PROVIDERS[COLLEGE_SITE_PROVIDERS["PRESTO_ALT"] = 4] = "PRESTO_ALT";
    COLLEGE_SITE_PROVIDERS[COLLEGE_SITE_PROVIDERS["STREAMLINE"] = 5] = "STREAMLINE";
})(COLLEGE_SITE_PROVIDERS || (exports.COLLEGE_SITE_PROVIDERS = COLLEGE_SITE_PROVIDERS = {}));
exports.COLLEGE_SITE_PROVIDERS_MAP = {
    'None/Other': 0,
    'Sidearm': 1,
    'Presto': 2,
    'WMT': 3,
    'Streamline': 5
};
var COLLEGE_DIVISIONS;
(function (COLLEGE_DIVISIONS) {
    COLLEGE_DIVISIONS["NCAA"] = "NCAA";
    COLLEGE_DIVISIONS["NAIA"] = "NAIA";
    COLLEGE_DIVISIONS["NCCAA"] = "NCCAA";
    COLLEGE_DIVISIONS["JUCO"] = "Junior College";
    COLLEGE_DIVISIONS["USCAA"] = "USCAA";
    COLLEGE_DIVISIONS["NONE"] = "";
})(COLLEGE_DIVISIONS || (exports.COLLEGE_DIVISIONS = COLLEGE_DIVISIONS = {}));
var COLLEGE_SUBDIVISIONS;
(function (COLLEGE_SUBDIVISIONS) {
    COLLEGE_SUBDIVISIONS["D1"] = "Division 1";
    COLLEGE_SUBDIVISIONS["D2"] = "Division 2";
    COLLEGE_SUBDIVISIONS["D3"] = "Division 3";
    COLLEGE_SUBDIVISIONS["NONE"] = "";
})(COLLEGE_SUBDIVISIONS || (exports.COLLEGE_SUBDIVISIONS = COLLEGE_SUBDIVISIONS = {}));
var LOCK_LEVELS;
(function (LOCK_LEVELS) {
    LOCK_LEVELS[LOCK_LEVELS["NONE"] = 0] = "NONE";
    LOCK_LEVELS[LOCK_LEVELS["SOFT"] = 1] = "SOFT";
    LOCK_LEVELS[LOCK_LEVELS["HARD"] = 2] = "HARD";
    LOCK_LEVELS[LOCK_LEVELS["FAILED"] = 3] = "FAILED";
    LOCK_LEVELS[LOCK_LEVELS["LOCKED"] = 4] = "LOCKED";
})(LOCK_LEVELS || (exports.LOCK_LEVELS = LOCK_LEVELS = {}));
exports.eventLinkTypes = ['root', 'scouts', 'schedule'];
exports.collegeLinkTypes = ['root', 'home', 'staff', 'roster', 'x', 'instagram'];
exports.conferenceLinkTypes = ['root', 'x', 'instagram'];
exports.allLinkTypes = exports.eventLinkTypes.concat(exports.collegeLinkTypes);
var ERcrtScrapingErrors;
(function (ERcrtScrapingErrors) {
    // ROOT_URL= 0,
    ERcrtScrapingErrors[ERcrtScrapingErrors["SPORT_URL"] = 1] = "SPORT_URL";
    ERcrtScrapingErrors[ERcrtScrapingErrors["STAFF_URL"] = 2] = "STAFF_URL";
    ERcrtScrapingErrors[ERcrtScrapingErrors["SCHEDULE_URL"] = 3] = "SCHEDULE_URL";
    ERcrtScrapingErrors[ERcrtScrapingErrors["NO_IMAGE"] = 4] = "NO_IMAGE";
    ERcrtScrapingErrors[ERcrtScrapingErrors["NO_STAFF_IMAGES"] = 5] = "NO_STAFF_IMAGES";
    ERcrtScrapingErrors[ERcrtScrapingErrors["NO_STAFF_EMAILS"] = 6] = "NO_STAFF_EMAILS";
    ERcrtScrapingErrors[ERcrtScrapingErrors["NO_STAFF"] = 7] = "NO_STAFF";
    ERcrtScrapingErrors[ERcrtScrapingErrors["MISSING_URL"] = 8] = "MISSING_URL";
    ERcrtScrapingErrors[ERcrtScrapingErrors["SCHEDULE_TEST_FAIL"] = 9] = "SCHEDULE_TEST_FAIL";
    ERcrtScrapingErrors[ERcrtScrapingErrors["SCHEDULE_ROW_FAIL"] = 10] = "SCHEDULE_ROW_FAIL";
    ERcrtScrapingErrors[ERcrtScrapingErrors["SCHEDULE_NO_GAMES"] = 11] = "SCHEDULE_NO_GAMES";
    // SCHEDULE_DUPLICATE_OPPONENTS = 12,
    ERcrtScrapingErrors[ERcrtScrapingErrors["SCHEDULE_SEASON_ERRORS"] = 13] = "SCHEDULE_SEASON_ERRORS";
    ERcrtScrapingErrors[ERcrtScrapingErrors["ADDRESS_ISSUE"] = 14] = "ADDRESS_ISSUE";
    ERcrtScrapingErrors[ERcrtScrapingErrors["SCHEDULE_MISSING_OPPONENT"] = 15] = "SCHEDULE_MISSING_OPPONENT";
    ERcrtScrapingErrors[ERcrtScrapingErrors["NO_DIVISION"] = 16] = "NO_DIVISION";
    ERcrtScrapingErrors[ERcrtScrapingErrors["NO_SUBDIVISION"] = 17] = "NO_SUBDIVISION";
})(ERcrtScrapingErrors || (exports.ERcrtScrapingErrors = ERcrtScrapingErrors = {}));
exports.ERcrtScrapingErrors_MAP = {
    // 0: 'No Root Url',
    1: 'No Sport Url',
    2: 'No Staff Url',
    3: 'No Schedule Url',
    4: 'No main image',
    5: 'No staff images',
    6: 'No staff emails.',
    7: 'No staff found.',
    // 8: 'Missing a url',
    // 9: 'Schedule year test failed',
    10: 'No schedule rows found',
    11: 'No games found',
    12: 'Duplicate opponents found',
    13: 'Season has errors',
    14: 'Bad or no address',
    15: 'Schedule with missing opponent(s)',
    16: 'No division set',
    17: 'No NCAA sub-division set'
};
exports.COLLEGE_SPORTS = [
    {
        label: 'Soccer',
        value: 'soccer'
    },
    {
        label: 'Others Coming Soon',
        value: 'other'
    }
];
exports.COLLEGE_GENDERS = [
    {
        label: 'Men',
        value: 'men'
    },
    {
        label: 'Women',
        value: 'women'
    }
];
class College extends model_core_1.Base {
    constructor(data, olMap) {
        super('colleges', 14);
        this.title = '';
        this.alt_titles = [];
        this.links = [];
        this.sport = '';
        this.gender = null;
        this.image = null;
        this.staff = [];
        this.provider = COLLEGE_SITE_PROVIDERS.NONE;
        this.division = COLLEGE_DIVISIONS.NONE;
        this.subdivision = COLLEGE_SUBDIVISIONS.NONE;
        this.conference = '';
        this.state = '';
        this.city = '';
        this.address = null;
        this.tz = '';
        this.errors = {};
        this.errorCount = 0;
        this.record = '';
        this.conf = null;
        this.seasons = new model_core_1.ChildArray();
        this._m = ['title', 'image'];
        this._s = ['title', 'alt_titles', 'staff', 'division', 'subdivision', 'conf', 'address', 'errors', 'image', 'gender', 'sport', 'record', 'errorCount'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, College);
        exports.collegeLinkTypes.forEach(id => {
            if (!this.getLink(id)) {
                this.add('links', { id: id, url: '', lock: 0, error: ERcrtScrapingErrors.MISSING_URL });
            }
        });
    }
    getLink(id) {
        var _a;
        return (_a = this.links) === null || _a === void 0 ? void 0 : _a.find(l => l.id === id);
    }
    getStaffWithImages() {
        var _a;
        return ((_a = this.staff) === null || _a === void 0 ? void 0 : _a.reduce((withImg, s) => {
            var _a;
            if ((_a = s.image) === null || _a === void 0 ? void 0 : _a.src) {
                withImg.push(s);
            }
            return withImg;
        }, [])) || [];
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        if (!opts.properties) {
            opts.properties = [];
        }
        await super.loadAll(opts, stack, p);
    }
    toJSON() {
        var _a;
        let r = super.toJSON();
        r.errorCount = (_a = Object.keys(this.errors || {})) === null || _a === void 0 ? void 0 : _a.reduce((n, key) => {
            if (this.errors[key]) {
                n++;
            }
            return n;
        }, 0);
        return r;
    }
    cleanStaff() {
        var _a, _b, _c, _d, _e;
        // If we scraped data from a coaches-only page (not a roster page)
        // then we won't cull it off even if there's no email.  There are actually
        // a number of msoc/coaches/index pages that have a coach with name only.
        let coachPage = !!((_b = (_a = this.getLink('staff')) === null || _a === void 0 ? void 0 : _a.url) === null || _b === void 0 ? void 0 : _b.match(/coach/));
        if ((_c = this.staff) === null || _c === void 0 ? void 0 : _c.length) {
            for (let i = ((_d = this.staff) === null || _d === void 0 ? void 0 : _d.length) - 1; i >= 0; i--) {
                if (!this.staff[i].name) {
                    this.staff.splice(i, 1);
                }
                else if ((!this.staff[i].email || !((_e = this.staff[i].title) === null || _e === void 0 ? void 0 : _e.match(/coach/i))) && !coachPage) {
                    this.staff.splice(i, 1);
                }
            }
        }
    }
    save(parent) {
        var _a;
        if (!this.id) {
            throw `Cannot save college without id  (${this.title}).`;
        }
        if (!((_a = this.getLink('root')) === null || _a === void 0 ? void 0 : _a.url) && this.id !== 'not-found') {
            throw `Cannot save college without root url (${this.title}-${this.id}).`;
        }
        // Remove any empty staff items
        this.cleanStaff();
        return super.save(parent);
    }
    async toSearchJSON(opts) {
        var _a, _b;
        let r = await super.toSearchJSON(opts);
        if (((_a = this.address) === null || _a === void 0 ? void 0 : _a.latitude) && ((_b = this.address) === null || _b === void 0 ? void 0 : _b.longitude)) {
            r._geoloc = {
                lat: Number(this.address.latitude),
                lng: Number(this.address.longitude)
            };
        }
        return r;
    }
}
exports.College = College;
class Conference extends model_core_1.Base {
    constructor(data, olMap) {
        super('conferences', 13);
        this.name = '';
        this.long_name = '';
        this.links = [];
        this.sports = [];
        this.image = null;
        this.division = COLLEGE_DIVISIONS.NONE;
        this.subdivision = COLLEGE_SUBDIVISIONS.NONE;
        this._m = ['name', 'long_name'];
        this._s = ['name', 'long_name', 'division', 'subdivision', 'image', 'links', 'sports'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, College);
        exports.conferenceLinkTypes.forEach(id => {
            if (!this.getLink(id)) {
                this.add('links', { id: id, url: '', lock: 0, error: ERcrtScrapingErrors.MISSING_URL });
            }
        });
    }
    getLink(id) {
        var _a;
        return (_a = this.links) === null || _a === void 0 ? void 0 : _a.find(l => l.id === id);
    }
}
exports.Conference = Conference;
class CollegeRef extends model_core_1.Base {
    get results() {
        return {
            emails: this.results_emails,
            views: this.results_views,
            clicks: this.results_clicks,
            replies: this.results_replies
        };
    }
    constructor(data, olMap) {
        super('college_refs', 14);
        this.college = null;
        this.ref = null;
        this.notes = '';
        this.questionnaire = false;
        this.rating = { weight: 0 };
        this.results_emails = 0;
        this.results_views = 0;
        this.results_clicks = 0;
        this.results_replies = 0;
        this.eRef_ids = [];
        this.eRefs = [];
        this._m = ['ref'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, CollegeRef);
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        if (this.ref) {
            this.college = new College(opts.getDoc ? await opts.getDoc(this.ref) : await this.ref.get(), index_1.olm);
        }
        await super.loadAll(opts, stack, p);
    }
    toJSON() {
        var _a;
        let r = super.toJSON();
        r.ref = ((_a = this.college) === null || _a === void 0 ? void 0 : _a._docRef) || this.ref;
        r.eRef_ids = this.eRefs.map(e => e.id);
        delete r.college;
        return r;
    }
    calc(factors) {
        var _a, _b;
        if ((factors === null || factors === void 0 ? void 0 : factors.length) && ((_b = (_a = this.rating) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.length)) {
            this.rating.items.forEach(item => {
                let factor = factors.find(f => f.label.toLowerCase() === item.label.toLowerCase());
                if (factor === null || factor === void 0 ? void 0 : factor.importance) {
                    item.weight = Number(factor.importance || 0) * Number(item.rating || 0);
                }
                else {
                    item.weight = 0;
                }
            });
            this.rating.weight = this.rating.items.reduce((total, item) => {
                total += item.weight;
                return total;
            }, 0);
        }
    }
    async save(parent, setDoc) {
        var _a, _b, _c;
        if (!((_a = this.college) === null || _a === void 0 ? void 0 : _a._docRef) && !this.ref) {
            throw `Cannot save College reference until it has a docRef!`;
        }
        else {
            this.id = this.id || ((_b = this.college) === null || _b === void 0 ? void 0 : _b.id);
            this.active = (this.active !== null) ? this.active : true;
            this.last_date = this.last_date || Date.now();
            this.ref = ((_c = this.college) === null || _c === void 0 ? void 0 : _c._docRef) || this.ref;
        }
        return super.save(parent, setDoc);
    }
}
exports.CollegeRef = CollegeRef;
class RcrtStaffChange extends model_core_1.Base {
    constructor(data, olMap) {
        super('staff_changes', 14);
        this.additions = [];
        this.removals = [];
        this.ref = null;
        this.college = null;
        this.correct = 0;
        this.incorrect = 0;
        this._m = ['ref'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, CollegeRef);
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        if (this.ref) {
            this.college = new College(opts.getDoc ? await opts.getDoc(this.ref) : await this.ref.get(), index_1.olm);
        }
        await super.loadAll(opts, stack, p);
    }
}
exports.RcrtStaffChange = RcrtStaffChange;
class CollegeSeason extends model_core_1.Base {
    constructor(data, olMap) {
        super('seasons', 14);
        this.year = '';
        this.wins = null;
        this.losses = null;
        this.ties = null;
        this.errors = [];
        this.season_games = new model_core_1.ChildArray();
        this.link = { id: 'schedule', url: '' };
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, CollegeSeason);
    }
    async loadAll(opts, stack, p) {
        var _a;
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        await super.loadAll(opts, stack, p);
        if ((_a = this.season_games) === null || _a === void 0 ? void 0 : _a.length) {
            this.season_games.sort((a, b) => (a.date < b.date) ? -1 : 1);
        }
    }
    toJSON() {
        let r = super.toJSON();
        delete r.season_games;
        return r;
    }
    save(parent) {
        if (!this.id) {
            throw `Cannot save event without id.`;
        }
        this.last_date = Date.now();
        return super.save(parent);
    }
}
exports.CollegeSeason = CollegeSeason;
class CollegeSeasonGame extends model_core_1.Base {
    toString() {
        return `${this.location === 'h' ? 'vs ' : '@ '} ${this.opponent.title}: ${new Date(...this.time).toString()}: ${this.result || ''} ${this.points_for || ''}-${this.points_against || ''}`;
    }
    getDate() {
        var _a;
        let d = new Date(...this.time);
        if (((_a = this.time) === null || _a === void 0 ? void 0 : _a.length) && this.time[3] !== undefined) {
            return (0, date_fns_1.format)(d, 'eeee, MMM d h:mm a ');
        }
        else {
            return (0, date_fns_1.format)(d, 'eeee, MMM d');
        }
    }
    hasTime() {
        return !!this.time[3];
    }
    constructor(data, olMap) {
        super('season_games', 6);
        this.time = [];
        this.result = null;
        this.resultNote = '';
        this.points_for = null;
        this.points_against = null;
        this.location = null;
        this.ref = null;
        this.opponent = null;
        this.opponent_title = '';
        this.opponent_reserve = false;
        // recapUrl?: string = '';
        this.notes = '';
        this.exhibition = false;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, CollegeSeasonGame);
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        if (this.ref) {
            this.opponent = new College(opts.getDoc ? await opts.getDoc(this.ref) : await this.ref.get(), index_1.olm);
        }
        await super.loadAll(opts, stack, p);
    }
    toJSON() {
        let r = super.toJSON();
        delete r.opponent;
        return r;
    }
    async save(parent, setDoc) {
        var _a, _b, _c;
        if (!((_a = this.opponent) === null || _a === void 0 ? void 0 : _a._docRef) && !this.ref && ((_b = this.opponent) === null || _b === void 0 ? void 0 : _b.id) !== 'not-found') {
            throw `Cannot save College reference until it has a docRef!`;
        }
        else {
            this.last_date = Date.now();
            this.ref = ((_c = this.opponent) === null || _c === void 0 ? void 0 : _c._docRef) || this.ref;
        }
        return super.save(parent, setDoc);
    }
}
exports.CollegeSeasonGame = CollegeSeasonGame;
index_1.olm.colleges = (ref, map) => {
    return new College(ref, map);
};
index_1.olm.conferences = (ref, map) => {
    return new Conference(ref, map);
};
index_1.olm.college_refs = (ref, map) => {
    return new CollegeRef(ref, map);
};
index_1.olm.staff_changes = (ref, map) => {
    return new RcrtStaffChange(ref, map);
};
index_1.olm.seasons = (ref, map) => {
    return new CollegeSeason(ref, map);
};
index_1.olm.season_games = (ref, map) => {
    return new CollegeSeasonGame(ref, map);
};
